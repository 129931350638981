export const DEFAULT_QUERY = {
  query: undefined,
  currentPage: 1,
  fetchSize: 20,
  orderBy: 'scoreSort',
  municipality: false,
  citizens: false,
  otakantaa: false,
  proposals: false,
};
export const MAX_HIGHLIGHTS = 3;
export const SERVICES_CURRENT_VALUES = {
  citizens: 'kansalaisaloite.fi',
  municipality: 'kuntalaisaloite.fi',
}; //for detecting current site, these must not be translated
export const SERVICES = ['citizens', 'municipality', 'otakantaa', 'proposals'];
export const SERVICELINKS = [
  'demokratia',
  'citizens',
  'hyvinvointialuealoite',
  'municipality',
  'otakantaa',
  'digiraati',
  'proposals',
  'vaalit',
  'puoluerekisteri',
];
export const BASE_URL = '/rest/search/ubersearch';
export const EMPTY_RESULTS = { totalElements: undefined, items: [] };
export const TOOLBAR_HEIGHT = '45px';
