import { styled } from '@mui/material/styles';
import Search from './Search';
import { AppBar as AB, Box, Toolbar } from '@mui/material';
import Navigation from './Navigation';
import { TOOLBAR_HEIGHT } from '../../../constants';
import { useIntl } from 'react-intl';
import messages from '../../../messages';

const StyledAppBar = styled(AB)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
  borderBottom: '1px solid #f2f2f2',
  zIndex: theme.zIndex.drawer + 1,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  maxWidth: 1250,
  width: '100%',
  height: TOOLBAR_HEIGHT,
  minHeight: TOOLBAR_HEIGHT,
  [theme.breakpoints.down('m')]: {
    justifyContent: 'start',
  },
}));
export default function AppBar() {
  const intl = useIntl();
  return (
    <StyledAppBar
      position={'absolute'}
      aria-label={intl.formatMessage(messages.serviceNav)}
    >
      <StyledToolbar>
        <Navigation />
        <Box>
          <Search />
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  );
}
