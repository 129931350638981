import { defineMessages } from 'react-intl';

// App spesific common messages
export default defineMessages({
  kansalaisaloite: { defaultMessage: 'Kansalaisaloite.fi' },
  kuntalaisaloite: { defaultMessage: 'Kuntalaisaloite.fi' },
  hyvinvointialuealoite: { defaultMessage: 'Hyvinvointialuealoite.fi' },
  digiraati: { defaultMessage: 'Digiraati' },
  demokratia: { defaultMessage: 'Demokratia.fi' },
  lausuntopalvelu: { defaultMessage: 'Lausuntopalvelu.fi' },
  citizens: { defaultMessage: 'Kansalaisaloite.fi' },
  municipality: { defaultMessage: 'Kuntalaisaloite.fi' },
  proposals: { defaultMessage: 'Lausuntopalvelu.fi' },
  vaalit: { defaultMessage: 'Vaalit.fi' },
  otakantaa: { defaultMessage: 'Otakantaa.fi' },
  search: { defaultMessage: 'Hae palveluista' },
  openSearch: { defaultMessage: 'Avaa haku' },
  closeSearch: { defaultMessage: 'Sulje haku' },
  close: { defaultMessage: 'Sulje' },
  searchAll: { defaultMessage: 'Hae kaikista palveluista' },
  resultCount: {
    defaultMessage: 'Hakusanalla "{term}" löytyi {resultCount} tulosta.',
  },
  noResults: { defaultMessage: 'Hakusanalla "{term}" ei löytynyt tuloksia.' },
  showMore: { defaultMessage: 'Näytä lisää' },
  instructionsBtn: { defaultMessage: 'Näytä ohjeet' },
  instructions: {
    defaultMessage:
      'Hakupalvelun avulla voit hakea käyttäjien lisäämää sisältöä (esim. aloitteita, hankkeita ja lausuntoja) oikeusministeriön kansalaisaloite.fi, kuntalaisaloite.fi, lausuntopalvelu.fi sekä otakantaa.fi -palveluista. Voit kirjoittaa hakukenttään useampia hakusanoja. Voit kohdistaa haun yhteen tai useampaan palveluun.',
  },
  openInstructions: { defaultMessage: 'Ovaa ohjeet' },
  closeInstructions: { defaultMessage: 'Sulje ohjeet' },
  services: { defaultMessage: 'Demokratiapalvelut' },
  fetchError: {
    defaultMessage: 'Haun aikana tapahtui virhe. Hakua ei voitu suorittaa.',
  },
  serviceNav: { defaultMessage: 'Palveluvalikko' },
  puoluerekisteri: { defaultMessage: 'Puoluerekisteri.fi' },
  municipalityUrl: { defaultMessage: 'https://www.kuntalaisaloite.fi' },
  hyvinvointialuealoiteUrl: {
    defaultMessage: 'https://www.hyvinvointialuealoite.fi/',
  },
  digiraatiUrl: { defaultMessage: 'https://www.digiraati.fi/' },
  citizensUrl: { defaultMessage: 'https://www.kansalaisaloite.fi' },
  vaalitUrl: { defaultMessage: 'https://www.vaalit.fi' },
  otakantaaUrl: { defaultMessage: 'https://www.otakantaa.fi' },
  proposalsUrl: { defaultMessage: 'https://www.lausuntopalvelu.fi' },
  demokratiaUrl: { defaultMessage: 'https://www.demokratia.fi' },
  puoluerekisteriUrl: { defaultMessage: 'https://www.puoluerekisteri.fi' },
  selectService: { defaultMessage: 'Valitse vähintään yksi haettava palvelu.' },
});
